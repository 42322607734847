var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var NativeCustomEvent = _global.CustomEvent;

function useNative() {
  try {
    var p = new NativeCustomEvent("cat", {
      detail: {
        foo: "bar"
      }
    });
    return "cat" === p.type && "bar" === p.detail.foo;
  } catch (e) {}

  return false;
}
/**
 * Cross-browser `CustomEvent` constructor.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent.CustomEvent
 *
 * @public
 */


exports = useNative() ? NativeCustomEvent : // IE >= 9
"function" === typeof document.createEvent ? function CustomEvent(type, params) {
  var e = document.createEvent("CustomEvent");

  if (params) {
    e.initCustomEvent(type, params.bubbles, params.cancelable, params.detail);
  } else {
    e.initCustomEvent(type, false, false, void 0);
  }

  return e;
} : // IE <= 8
function CustomEvent(type, params) {
  var e = document.createEventObject();
  e.type = type;

  if (params) {
    e.bubbles = Boolean(params.bubbles);
    e.cancelable = Boolean(params.cancelable);
    e.detail = params.detail;
  } else {
    e.bubbles = false;
    e.cancelable = false;
    e.detail = void 0;
  }

  return e;
};
export default exports;